@import '~antd/lib/style/themes/default.less';
@import './style/table';
@import './style/pagination';
@import './style/reset.css';

@basicColor: rgba(0, 185, 239, 1);

:global(#root) {
  height: 100%;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.globalSpin {
  width: 100%;
  margin: 40px 0 !important;
}

:global {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: rgba(73, 81, 96, 1);
  }
}

:global {
  .yl_clearfix {
    display: block;
    overflow: auto;
    zoom: 1;

    &:after {
      content: ' ';
      display: block;
      font-size: 0;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
  .yl_ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-spin-container {
    overflow: visible !important;
  }
  .ant-menu-submenu-popup {
    .ant-menu {
      background-color: #1c1c1c;
      .ant-menu-item {
        a {
          color: rgba(255, 255, 255, 0.65);
          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      i {
        color: #fff;
      }
    }
  }

  //音频播放器
  input[type='range'] {
    border-radius: 10px; /*这个属性设置使填充进度条时的图形为圆角*/
    cursor: pointer;
    margin-top: 8px;
    outline: none;
    -webkit-appearance: none; /*清除系统默认样式*/
    width: 320px;
    background: -webkit-linear-gradient(@basicColor, @basicColor) no-repeat,
      #ddd;
    background-size: 30% 100%; /*设置左右宽度比例*/
    vertical-align: middle;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 4px;
    border-radius: 10px; /*将轨道设为圆角的*/
    //box-shadow: 0 1px 1px #def3f8, inset 0 .125em .125em #0d1112; /*轨道内置阴影效果*/
  }
  input[type='range']:focus {
    outline: none;
  }

  /*拖动块的样式*/
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    margin-top: -5px; /*使滑块超出轨道部分的偏移量相等*/
    background: @basicColor;
    border-radius: 50%; /*外观设置为圆形*/
  }
  input[type='range']::-ms-fill-lower {
    /*进度条已填充的部分*/
    height: 22px;
    border-radius: 10px;
    background: linear-gradient(to right, #059cfa, white 100%, white);
  }

  input[type='range']::-ms-fill-upper {
    /*进度条未填充的部分*/
    height: 22px;
    border-radius: 10px;
    background: #ffffff;
  }

  .media {
    video {
      display: none;
    }
    width: 440px;
    display: inline-block;
    vertical-align: text-bottom;
    .meida_audio {
      .meida_audio_player {
        height: 0;
      }
      .media-container {
        display: flex;
        .media-left {
          width: 330px;
          .seekbar {
            background-size: 0% 100%;
            vertical-align: top;
          }
          .media-time {
            width: 320px;
            display: flex;
            .time {
              display: inline-block;
              height: 18px;
              line-height: 18px;
              vertical-align: top;
              flex: 1;
              font-family: Helvetica;
              font-size: 10px;
              color: #bbbccd;
              letter-spacing: 0.83px;
            }
            .time-left {
              text-align: left;
            }
            .time_total {
              text-align: right;
            }
          }
          .seekbar-container {
            text-align: left;
          }
        }
        .media-right {
          width: 110px;
          text-align: left;
          .pause_btn {
            margin-top: 4px;
            width: 60px;
            color: transparent;
            border: 0;
            background: transparent;
            outline: none;
            cursor: pointer;
            height: 24px;
            svg {
              font-size: 18px;
              font-size: 30px;
              margin-left: 30px;
              .bofang_svg__lujing,
              .zanting_svg__lujing {
                fill: @basicColor;
              }
            }
          }
          .media-name {
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            margin-left: 32px;
            display: inline-block;
            vertical-align: text-bottom;
            height: 32px;
            line-height: 32px;
          }
        }
      }
    }
  }

  .operate-text {
    color: @primary-color;
    cursor: pointer;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: transparent;
  position: relative;
}

::-webkit-scrollbar-button {
  display: block;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 2px hsla(0, 0%, 61%, 0.3);
  background-color: rgba(231, 228, 228, 0.89);
  right: 0px;
}

@layout-header-background: #fff;@layout-header-height: 120px;@layout-footer-background: #fff;@primary-color: #337CDB;@link-color: #337CDB;@success-color: #2FC27A;@warning-color: #FFD02B;@error-color: #EF5757;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@menu-item-height: 48px;